<script setup lang="ts">
import TasksPanelButton from "./TasksPanelButton.vue"
import { computed } from "vue"
import useTaskPanelList from "@/ContextTab/modules/news/tasks-panel/composables/useTaskPanelList"
import { useStore } from "@/ContextTab/useStore"
import type { TaskPanelMenuGroupType } from "./types"
import type { AlertsStore } from "@/ContextApp/stores/news/task-panel/alerts"
import type { RefsStore } from "@/ContextApp/stores/references"

defineProps<{ active: string }>()

const emit = defineEmits<{
  (e: "click", tab: TaskPanelMenuGroupType): void
}>()

const refsStore = useStore<RefsStore>("references", {
  shared: true,
})
const alertsStore = useStore<AlertsStore>("alerts", {
  shared: true,
})

const waitingFlashCount = computed(() => alertsStore?.data.waitingFlashCount)
const waitingExpressCount = computed(
  () => alertsStore?.data.waitingExpressCount
)
const inProgressFlashCount = computed(
  () => alertsStore?.data.inProgressFlashCount
)
const inProgressExpressCount = computed(
  () => alertsStore?.data.inProgressExpressCount
)

const initialLoaded = computed(
  () =>
    alertsStore?.data.initialLoaded &&
    waitingInitialLoaded.value &&
    inProgressInitialLoaded.value
)

const { initialLoaded: waitingInitialLoaded } = useTaskPanelList("waiting")
const { initialLoaded: inProgressInitialLoaded } =
  useTaskPanelList("inProgress")

const flashType = computed(() => refsStore.data?.newsTypesByName?.["FLASH"]?.id)
const expressType = computed(
  () => refsStore.data?.newsTypesByName?.["EXPRESS"]?.id
)
</script>

<template>
  <div class="tasks-panel-menu">
    <div class="tasks-panel-menu__group">
      <div class="tasks-panel-menu__group__title">вход</div>
      <TasksPanelButton
        :typeId="flashType"
        :alertsCount="waitingFlashCount"
        :loading="!initialLoaded"
        @click="emit('click', 'waiting')"
      />
      <TasksPanelButton
        :typeId="expressType"
        :alertsCount="waitingExpressCount"
        :loading="!initialLoaded"
        @click="emit('click', 'waiting')"
      />
    </div>
    <div class="tasks-panel-menu__group">
      <div class="tasks-panel-menu__group__title">работа</div>
      <TasksPanelButton
        :typeId="flashType"
        :alertsCount="inProgressFlashCount"
        :loading="!initialLoaded"
        @click="emit('click', 'inProgress')"
      />
      <TasksPanelButton
        :typeId="expressType"
        :alertsCount="inProgressExpressCount"
        :loading="!initialLoaded"
        @click="emit('click', 'inProgress')"
      />
    </div>
  </div>
</template>

<style lang="postcss">
.tasks-panel-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: var(--color-black);
  padding: 1rem 0;
  border-bottom: 1px solid var(--color-gray);
}
.tasks-panel-menu__group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tasks-panel-menu__group__title {
  text-align: center;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 700;
  color: #dddddd;
}
</style>
