<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsTabs from "@/ContextTab/components/UI/PsTabs.vue"
import { ref, computed } from "vue"
import useAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"
import useNewsActions from "@/ContextTab/modules/news/composables/useNewsActions"
import { tabs } from "@/ContextTab/modules/news/tasks-panel/components/nav/tabs"
import type { TaskPanelSetType } from "./nav/types"
import { useStoreAsync } from "@/ContextTab/useStore"
import type { NewsStore } from "@/ContextApp/stores/news"
import type { TaskPanelStore } from "@/ContextApp/stores/news/task-panel"
import { ACCESS } from "@/lib/accessCodes"

withDefaults(
  defineProps<{
    modelValue: TaskPanelSetType
    isLegacyRoute: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: "close"): void
}>()

const { getIsUserHasAllPermissions } = useAuthInfo()

const newsStore = await useStoreAsync<NewsStore>("news", {
  shared: true,
})
const store = await useStoreAsync<TaskPanelStore>("taskPanel")

const loading = ref(false)
const message = ref(null)

const permittedTabs = computed(() =>
  tabs.filter((tab) =>
    (tab.id === "waiting" && getIsUserHasAllPermissions([ACCESS.NEWS.INCOMING])) ||
    (tab.id === "inProgress" && getIsUserHasAllPermissions([ACCESS.NEWS.IN_WORK])) ||
    (tab.id === "my" && getIsUserHasAllPermissions([ACCESS.NEWS.USER]))
  )
)

const currTabId = defineModel<TaskPanelSetType>({ required: true })

const currentTabName = computed(() => {
  const curTab = tabs.find((tab) => tab.id === currTabId.value)
  return curTab ? curTab.label : ""
})

const { open } = useNewsActions(loading, message)

const pick = async (id: number) => {
  loading.value = true
  await newsStore.pick(id)
  await open(id)
  loading.value = false
}
</script>

<template>
  <PsTabs v-model="currTabId" :tabs="permittedTabs" class="task-panel-tabs">
    <template #suffix>
      <PsButton
        class="close"
        :circle="true"
        :border="false"
        size="sm"
        @click="emit('close')"
      >
        <PsIcon type="font" name="close" size="14" />
      </PsButton>
    </template>
    <template #default="{ tab }">
      <component
        :is="tab?.component"
        v-if="tab?.component"
        :dateMode="store.data.dateMode"
        :isLegacyRoute="isLegacyRoute"
        :loading="loading"
        @dateModeChanged="store.changeDateMode"
        @open="open"
        @pick="pick"
      />
      <div v-else>Вкладка "{{ currentTabName }}"</div>
    </template>
  </PsTabs>
</template>

<style lang="postcss">
.task-panel-tabs {
  --tabs--header--background: #fff6e6;
  --tabs--tab--padding: 0;
  --tabs--padding-top: 1rem;
  --tabs--header--border-bottom-color: var(--color-gray);
  --tabs--header--border-bottom-width: 1px;
  --tabs--header--margin-bottom: 0;

  &.tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    max-height: 100vh;
  }

  &.tabs .tabs-header {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-bottom: 1px solid #8b8b8b;
    overflow: visible;
  }

  .tab {
    overflow-y: hidden;
    height: 100%;
  }

  & .tab-body {
    height: 100%;
  }

  .close.-transparent:not(:disabled, .-disabled):hover {
    color: #bd985c;
    background-color: #f3e4ca;
  }
}
</style>
