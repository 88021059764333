<script setup lang="ts">
import TasksPanelListItem from "../components/TasksPanelListItem.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import NewsAssignBadge from "@/ContextTab/modules/news/tasks-panel/components/NewsAssignBadge.vue"
import NewsItemComment from "@/ContextTab/modules/news/tasks-panel/components/NewsItemComment.vue"
import { toRef, watch } from "vue"
import { DateMode } from "@/ContextTab/components/Date/types"
import useNewsItem from "@/ContextTab/modules/news/composables/useNewsItem"

const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    dateMode?: DateMode
    isLegacyRoute: boolean
    loading?: boolean
  }>(),
  {
    isSelected: false,
    dateMode: DateMode.Regular,
    loading: false,
  }
)
const emit = defineEmits<{
  (e: "open", id: number): void
  (e: "pick", id: number): void
}>()

const { newsItemIsRead, setNewsItemAsRead } = useNewsItem(
  toRef(() => props.item.id)
)

const open = (handleDblClick = false) => {
  setNewsItemAsRead()
  emit("open", props.item.id)

  if (handleDblClick) {
    emit("pick", props.item.id)
  }
}

const pick = () => {
  emit("pick", props.item.id)
}

watch(
  () => props.isSelected,
  next => {
    if (next && !newsItemIsRead.value) {
      setNewsItemAsRead()
    }
  }
)
</script>

<template>
  <TasksPanelListItem
    class="waiting-list-item"
    :item="item"
    :isSelected="isSelected"
    :dateMode="dateMode"
    :showReadStatus="true"
    @dblclick="() => open(true)"
  >
    <template #before-text>
      <NewsAssignBadge :itemId="item.id" />
    </template>
    <template #expansion-extra>
      <NewsItemComment
        v-if="item.first_comment"
        class="waiting-list-item__comment"
        :comment="item.first_comment.body"
      />
    </template>
    <template #footer>
      <div v-if="isSelected" class="waiting-list-item__footer">
        <PsTooltip content="Взять в работу" placement="top">
          <PsButton
            circle
            :border="false"
            :disabled="loading"
            class="action-button"
            @click="pick"
          >
            <PsIcon name="button-icon-add" :size="24" />
          </PsButton>
        </PsTooltip>
        <PsTooltip
          :content="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          placement="top"
        >
          <PsButton
            circle
            :border="false"
            class="action-button"
            @click="() => open(false)"
          >
            <PsIcon name="button-icon-show-more" :size="24" />
          </PsButton>
        </PsTooltip>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<style lang="postcss" scoped>
.waiting-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}
</style>
