<script setup lang="ts">
import Tooltip from "./Popover/Tooltip.vue"
import type { TooltipProps } from "./Popover/types"

const props = withDefaults(defineProps<TooltipProps>(), {
  arrow: true,
})

const slots = defineSlots<{
  default(props?: any): any
  body?(props?: any): any
}>()
</script>

<template>
  <Tooltip v-bind="props" class="ps-tooltip">
    <slot />
    <template #body>
      <template v-if="slots.body">
        <slot name="body" :content="content" />
      </template>
      <template v-else>
        {{ content }}
      </template>
    </template>
  </Tooltip>
</template>

<style lang="postcss">
.ps-tooltip {
  --popover--background: var(--color-dark);
  --popover--color: var(--contrast-text-color-dark);
  --popover--border-color: var(--color-dark);
  --popover--font-size: 0.8rem;
}
</style>
