<script setup lang="ts">
import Popover from "./Popover.vue"
import type { TooltipProps } from "./types"

const props = withDefaults(defineProps<TooltipProps>(), {
  placement: "top"
})

const slots = defineSlots<{
  default(props?: any): any
  body?(props?: any): any
}>()
</script>

<template>
  <Popover trigger="hover" v-bind="{...props, ...$attrs}">
    <slot />
    <template #body>
      <template v-if="slots.body">
        <slot name="body" />
      </template>
      <template v-else>
        {{ content }}
      </template>
    </template>
  </Popover>
</template>
