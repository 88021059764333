<script setup lang="ts">
import MainNav from "./layout/MainNav.vue"
import AppUpdater from "@/ContextTab/components/AppUpdater.vue"
import ConnectionStatus from "@/ContextTab/components/ConnectionStatus.vue"
import SplashScreen from "@/ContextTab/components/SplashScreen.vue"
import WorkspaceSwitcher from "@/ContextTab/components/WorkspaceSwitcher.vue"
import MainGrid from "@/ContextTab/layout/grid/MainGrid.vue"
import WithLogin from "@/ContextTab/modules/auth/components/WithLogin.vue"
import MainMenu from "@/ContextTab/modules/menu/components/MainMenu.vue"
import MenuButton from "@/ContextTab/modules/menu/components/MenuButton.vue"
import ShareModal from "@/ContextTab/modules/news/share-modal/components/ShareModal.vue"
import TasksPanelMenu from "@/ContextTab/modules/news/tasks-panel/components/nav/TasksPanelMenu.vue"
import TasksPanel from "@/ContextTab/modules/news/tasks-panel/components/TasksPanel.vue"
import { computed, ref, provide, watch } from "vue"
import { useRoute, RouterView } from "vue-router"
import useBreakpoints from "@/ContextTab/composables/useBreakpoints"
import useShareModal from "@/ContextTab/modules/news/composables/useShareModal"
import { tabs } from "@/ContextTab/modules/news/tasks-panel/components/nav/tabs"
import appUpdate from "@/ContextTab/services/update"
import { useStore } from "@/ContextTab/useStore"
import { type SetType } from "@/lib/types"
import { KeyboardHandlerKey } from "@/injectionKeys"
import { KeyboardHandler } from "@/ContextTab/services/keyboardHandler"
import { tabMetrics } from "@/ContextTab/services/tabMetrics"
import { AuthStatus, type AuthStore } from "@/ContextApp/stores/auth"
import type { InitStore } from "@/ContextApp/stores/init"

const currentTaskPanelTab = ref(tabs[0].id)

const menuShowed = ref(false)
const isTaskPanelShowed = ref(false)

const breakpoints = useBreakpoints()

const toggleMenu = () => {
  menuShowed.value = !menuShowed.value
}

const route = useRoute()
const isLegacyRoute = computed(() =>
  typeof route?.name === "string" ? route.name.includes("legacy") : false
)

const toggleTaskPanel = (tab: SetType) => {
  if (tab !== currentTaskPanelTab.value) {
    currentTaskPanelTab.value = tab
    isTaskPanelShowed.value = true
  } else {
    isTaskPanelShowed.value = !isTaskPanelShowed.value
  }
}

const closeTaskPanel = () => {
  isTaskPanelShowed.value = false
}

const initStore = useStore<InitStore>("init", {
  shared: true,
})

const authStore = useStore<AuthStore>("auth", {
  shared: true,
})



const hasUpdate = computed(() => appUpdate.hasUpdate.value)

function updateApp() {
  appUpdate.update()
}

const { shareModalVisible, hideShareModal } = useShareModal()
const toggleShareModal = (value: boolean) => {
  if (!value) {
    hideShareModal()
  }
}

const logout = () => {
  menuShowed.value = false
  localStorage.clear()
  authStore.logout()
}

watch(() => authStore?.data?.authorized, (authState) => {
  if (authState === AuthStatus.authorized) {
    tabMetrics.setUser({
      id: authStore?.data?.userId,
      name: authStore?.data?.login,
      email: authStore?.data?.login,
    })
  }
})

provide("breakpoints", breakpoints)

provide(KeyboardHandlerKey, new KeyboardHandler())
</script>

<template>
  <div id="application">
    <template v-if="authStore.created && authStore.data.authorized > 0">
      <ConnectionStatus />
      <AppUpdater
        v-if="hasUpdate"
        @update="updateApp"
      />
      <WithLogin
        :isAuthorized="authStore.data.authorized === AuthStatus.authorized"
        :needLogin="authStore.data.authorized !== AuthStatus.authorized"
      >
        <template v-if="initStore.data.state === 'started'">
          <SplashScreen />
        </template>
        <template v-else-if="initStore.data.state === 'ready'">
          <MainGrid
            :menuShowed="menuShowed"
            :isTaskPanelShowed="isTaskPanelShowed"
            @hideSidebar="menuShowed = false"
          >
            <template #aside>
              <MainNav>
                <template #group-main>
                  <TasksPanelMenu
                    :active="currentTaskPanelTab"
                    @click="toggleTaskPanel"
                  />
                  <WorkspaceSwitcher v-if="!isLegacyRoute" />
                </template>

                <template
                  v-if="!isLegacyRoute"
                  #group-secondary
                >
                  <MenuButton
                    :active="menuShowed"
                    @click="toggleMenu"
                  />
                </template>
              </MainNav>
            </template>

            <template #drawer>
              <MainMenu @close="toggleMenu" @logout="logout" />
            </template>

            <template #task-panel>
              <KeepAlive>
                <Suspense>
                  <TasksPanel
                    v-model="currentTaskPanelTab"
                    :isLegacyRoute="isLegacyRoute"
                    @close="closeTaskPanel"
                  />
                </Suspense>
              </KeepAlive>
            </template>

            <template #main>
              <RouterView v-slot="{ Component }">
                <template v-if="Component">
                  <KeepAlive>
                    <Suspense>
                      <component
                        :is="Component"
                        :isTaskPanelShowed="isTaskPanelShowed"
                      />
                    </Suspense>
                  </KeepAlive>
                </template>
              </RouterView>
            </template>
          </MainGrid>
          <ShareModal
            :modelValue="shareModalVisible"
            @update:modelValue="toggleShareModal"
          />
        </template>
      </WithLogin>
    </template>

    <div id="tooltips"></div>
    <div id="popups"></div>
    <div id="popovers"></div>
    <div id="message"></div>
    <div id="debug"></div>
  </div>
</template>

<style scoped>
#application {
  --workspace-switcher-size: 4.125rem;
  background: url("@/assets/images/bg_default_light.webp") center/cover
    no-repeat;
  overflow-y: hidden;
}

.workspaces-block {
  flex-grow: 1;
}

#debug {
  /* display: flex; */
  display: none;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  font-size: 2rem;
  background-color: #fff;
  padding: 10px;
}

@media screen and (max-width: 767.99px) {
  .workspaces-block {
    order: 1;
  }
}

#window-titlebar {
  position: fixed;
  box-sizing: border-box;
  color: var(--color-white);
  background: #0069e4;
  /* Use the environment variable for the left anchoring with a fallback. */
  left: env(titlebar-area-x, 0);
  /* Use the environment variable for the top anchoring with a fallback. */
  top: env(titlebar-area-y, 0);
  /* Use the environment variable for setting the width with a fallback. */
  width: env(titlebar-area-width, 100%);
  /* Use the environment variable for setting the height with a fallback. */
  height: env(titlebar-area-height, 33px);
  overflow: hidden;
  z-index: 99;
  padding-inline: var(--padding-left) var(--padding-right);
  display: flex;
  justify-content: start;
  align-items: center;

  & h1 {
    font-size: 90%;
    font-weight: bold;
  }
}
</style>
