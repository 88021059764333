<script setup lang="ts">
import { ISidebar } from "@inkline/inkline"

withDefaults(
  defineProps<{
    menuShowed?: boolean
    hideAside?: boolean
    isTaskPanelShowed?: boolean
  }>(),
  {
    menuShowed: false,
    hideAside: false,
    isTaskPanelShowed: false,
  }
)

const emit = defineEmits(["hideSidebar", "hideTaskPanel"])
</script>

<template>
  <ISidebar
    v-if="!hideAside"
    :modelValue="menuShowed"
    :collapse="true"
    :contentStyle="{ background: 'none' }"
    size="lg"
    color="dark"
    placement="left"
    to="#main-menu-drawer-target"
    class="menu-drawer"
    @update:modelValue="$event === false && emit('hideSidebar')"
  >
    <slot name="drawer" />
  </ISidebar>
  <div class="layout" :class="{ 'no-aside': hideAside }">
    <aside class="grid-layout-aside">
      <slot name="aside" />
    </aside>

    <ISidebar
      :class="{ 'no-aside': hideAside }"
      :modelValue="isTaskPanelShowed"
      :collapse="true"
      collapsePosition="relative"
      :contentStyle="{ background: 'none' }"
      size="lg"
      placement="left"
      class="task-panel"
      @update:modelValue="$event === false && emit('hideTaskPanel')"
    >
      <slot name="task-panel" />
    </ISidebar>

    <main>
      <slot name="main" />
    </main>
  </div>
</template>

<style scoped>
.menu-drawer {
  --sidebar--border-radius: 0;
  --sidebar--overlay--background: transparent;
}

.layout {
  display: grid;
  position: relative;
  height: 100%;
  overflow: hidden;

  & main {
    overflow: hidden;
    position: relative;
  }

  & aside {
    display: flex;
    height: 100%;
    position: relative;
  }
}

.task-panel {
  --sidebar--border-radius: 0;
  --sidebar--lg--width: 18rem;
  --sidebar--padding: 0;

  background-color: #fffaf2;
}

@media screen and (max-width: 575.99px) {
  .menu-drawer {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
  }
  .layout {
    grid-template-columns: auto;
    grid-template-rows:
      calc(100% - var(--workspace-switcher-size))
      var(--workspace-switcher-size);

    &.no-aside {
      grid-template-rows:
        100%
        0;
      grid-template-columns: 100%;
    }

    .grid-layout-aside {
      grid-row: 2 / -1;
      grid-column: 1 / -1;
      flex-direction: row;
      z-index: 2;
    }

    & main {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
    }
  }
}

@media screen and (min-width: 576px) {
  .menu-drawer {
    left: var(--workspace-switcher-size);
    top: 0;
  }
  .layout {
    grid-template-columns: var(--workspace-switcher-size) auto auto;
    /* grid-template-columns:
      var(--workspace-switcher-size)
      calc(100% - var(--workspace-switcher-size)); */
    grid-template-rows: 100%;

    &.no-aside {
      grid-template-columns: 0 100%;
    }
    .grid-layout-aside {
      grid-row: 1 / -1;
      flex-direction: column;
    }
  }
}

.logout {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
</style>
